import React from 'react'
import Routes from './routes'
import GlobalStyles from './GlobalStyles'

function App() {
  return (
    <>
      <Routes />
      <GlobalStyles />
    </>
  )
}

export default App
