import GomImg from '../assets/Areas/Devs/Gomes.svg'
import TauImg from '../assets/Areas/Devs/Tauana.svg'
import CarImg from '../assets/Areas//Devs/Carol.svg'
import MarImg from '../assets/Areas//Devs/Marco.svg'
import PasImg from '../assets/Areas//Devs/Passos.svg'
import JovImg from '../assets/Areas/Design/Jovana.svg'
import DogImg from '../assets/Areas//Devs/Douglas.svg'
import VitImg from '../assets/Areas/Design/Vitoria.svg'
import LucImg from '../assets/Areas/Design/Luciane.svg'
import AleImg from '../assets/Areas//Devs/Alessander.svg'
import AlesImg from '../assets/Areas//Devs/Alessandro.svg'
import PabImg from '../assets/Areas/Communication/Pablo.svg'
import MicImg from '../assets/Areas/Communication/Michely.svg'
import AbilImg from '../assets/Areas/Communication/Abigail.svg'

export const cardsData = [
  [
    {
      index: 0,
      name: 'Cândida Vitória',
      office: 'UX/UI Designer e UX Writer',
      imgProfile: VitImg,
      link_linkedin: '',
      link_insta: '',
    },
    {
      index: 1,
      name: 'Jovana Caetano',
      office: 'UX/UI Designer e Product Designer',
      imgProfile: JovImg,
      link_linkedin: '',
      link_insta: '',
    },
    {
      index: 2,
      name: 'Luciane Oliveira',
      office: 'UX/UI Designer',
      imgProfile: LucImg,
      link_linkedin: '',
      link_insta: '',
    },
  ],
  [
    {
      index: 3,
      name: 'Cândida Vitória',
      office: 'Produtora e Redatora',
      imgProfile: VitImg,
      link_linkedin: '',
      link_insta: '',
    },
    {
      index: 4,
      name: 'Jovana Caetano',
      office: 'Social Media Design ',
      imgProfile: JovImg,
      link_linkedin: '',
      link_insta: '',
    },
    {
      index: 5,
      name: 'Abigail Thaís Pinheiro',
      office: 'Analista de conteúdo ',
      imgProfile: AbilImg,
      link_linkedin: '',
      link_insta: '',
    },
    {
      index: 6,
      name: 'Michely Alves',
      office: 'Comunicadora  digital ',
      imgProfile: MicImg,
      link_linkedin: '',
      link_insta: '',
    },
    {
      index: 7,
      name: 'Pablo Alba',
      office: 'Designer digital',
      imgProfile: PabImg,
      link_linkedin: '',
      link_insta: '',
    },
  ],
  [
    {
      index: 8,
      name: 'Gabriel Gomes',
      office: 'Desenvolvedor Full Stack',
      imgProfile: GomImg,
      link_linkedin: '',
      link_insta: '',
    },
    // {
    //   index: 9,
    //   name: 'Tauana Pacheco',
    //   office: 'Desenvolvedora Web Front-end',
    //   imgProfile: TauImg,
    //   link_linkedin: '',
    //   link_insta: '',
    // },
    {
      index: 10,
      name: 'Marco Antônio',
      office: 'Desenvolvedor Full Stack',
      imgProfile: MarImg,
      link_linkedin: '',
      link_insta: '',
    },
    {
      index: 11,
      name: 'Gabriel Passos ',
      office: 'Desenvolvedor Front-end',
      imgProfile: PasImg,
      link_linkedin: '',
      link_insta: '',
    },
    // {
    //   index: 12,
    //   name: 'Alessandro Sena',
    //   office: 'Desenvolvedor Front-end',
    //   imgProfile: AlesImg,
    //   link_linkedin: '',
    //   link_insta: '',
    // },
    // {
    //   index: 13,
    //   name: 'Alessander David',
    //   office: 'Desenvolvedor Full Stack',
    //   imgProfile: AleImg,
    //   link_linkedin: '',
    //   link_insta: '',
    // },
    // {
    //   index: 14,
    //   name: 'Carolina Nogueira',
    //   office: 'Desenvolvedora Front-end',
    //   imgProfile: CarImg,
    //   link_linkedin: '',
    //   link_insta: '',
    // },
    // {
    //   index: 15,
    //   name: 'Douglas Santana',
    //   office: 'Desenvolvedor Full Stack',
    //   imgProfile: DogImg,
    //   link_linkedin: '',
    //   link_insta: '',
    // },
  ],
]
