import 'firebase/functions'
import firebase from 'firebase'

firebase.initializeApp({
  apiKey: 'AIzaSyBZJdrw3PgBghKInp744WrQKREqpnIhmOQ',
  authDomain: 'lanceiros-site.firebaseapp.com',
  projectId: 'lanceiros-site',
  storageBucket: 'lanceiros-site.appspot.com',
  messagingSenderId: '429212970729',
  appId: '1:429212970729:web:226f294fa367f483504a78',
  measurementId: 'G-6D5EVE41QM',
})

export function useMail() {
  var functions = firebase.app().functions('southamerica-east1')
  const sendMail = functions.httpsCallable('sendMail')
  return {
    send: (data) => sendMail(data),
  }
}