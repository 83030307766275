import styled from 'styled-components'

export const Main = styled.form`
  background-color: #2e2e2e;
  width: 100%;
  margin-bottom: 5%;
  margin-top: 5%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 1vw;

  .row-button {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  @media (max-width: 720px) {
    font-size: 6vw;
    line-height: 7vw;
    margin-top: 2%;

    .row-button {
      margin-bottom: 0%;
      margin-top: 10%;
      margin-left: 10%;
      align-items: center;
    }
  }
`

export const Border = styled.div`
  background-color: #ffffff;
  border-radius: 1vw;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 5%;
  margin-bottom: 5%;
  height: auto;

  text-align: center;

  @media (max-width: 720px) {
    padding-bottom: 1%;
    padding-top: 1%;
  }
`

export const Input = styled.input`
  color: #3b3a47;
  padding-bottom: 2%;
  padding-top: 2%;
  width: 75%;
  padding-right: 8%;
  margin-left: 15%;
  margin-bottom: 5%;
  border: none;
  border-bottom: 0.5px solid #585761;

  font-family: 'Roboto';
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 200;
  line-height: 2vw;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 720px) {
    font-size: 4vw;
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    width: 80%;
    margin-top: 5%;
  }
`

export const Title = styled.h1`
  color: #000000;
  font-family: 'IBM Plex Sans';
  font-size: 2.7vw;
  font-style: normal;
  font-weight: 500;
  line-height: 3vw;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 8%;
  padding-top: 5%;
  padding-bottom: 5%;

  @media (max-width: 720px) {
    br {
      display: none;
    }
    font-size: 5vw;
    line-height: 6vw;
  }
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 720px) {
    display: block;
  }
`
