import React from 'react'
import ImageLancer from '../../assets/Landing/Lancer.svg'
import { CardTitle, CardText, Card, ImgLancer } from './CardLancersStyles'

export default function CardLancers(props) {
  return (
    <Card>
      <ImgLancer src={ImageLancer} alt="Lanceiros" />
      <CardTitle>{props.title}</CardTitle>
      <CardText>{props.text}</CardText>
    </Card>
  )
}
