import React from 'react'
import Form from '../../components/Form'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Element, Link } from 'react-scroll'
import { useHistory } from 'react-router-dom'
import Pen from '../../assets/Landing/pen.svg'
import Nave from '../../assets/Landing/nave.svg'
import Code from '../../assets/Landing/code.svg'
import Case from '../../assets/Landing/case.svg'
import UiUx from '../../assets/Landing/layout.svg'
import OutdoorImg from '../../assets/Landing/outdoor.svg'
import ImageLancer from '../../assets/Landing/Lancer.svg'
import CardLancers from '../../components/CardLancers/CardLancers'
import { ImgLancer } from '../../components/CardLancers/CardLancersStyles'
import {
  Main,
  Outdoor,
  Title,
  Content,
  Text,
  RowMobile,
  RowButtons,
  AdjustCard,
  ImageMobile,
  DefaultButton,
  ImagePrincipal,
  DiscoverLancers,
  TitleDiscover,
  Experience,
  Card,
  Option,
  ThreeContent,
  Branch,
  CardContent,
  ThreeTextBox,
  EmpityBox,
  Ball,
  FormContainer,
} from './LandingStyles'

export default function Landing() {
  const history = useHistory()

  function handleClick(index, active) {
    history.push('/areas', { index: index, active: active })
  }

  return (
    <>
      <Header areas={false} />
      <Main>
        <Outdoor>
          <RowMobile id='first-title'>
            <Title >
              Nossa equipe garante autonomia gerando experências únicas através
              da inovação
            </Title>
            <ImageMobile
              src={OutdoorImg}
              mobile={true}
              alt="Outdoor Lanceiros"
            />
          </RowMobile>
          <Content>
            <div>
              <Text>
                Somos gigantes no que fazemos, oferecemos inovação digital e
                automatização completa no processo de toda jornada do seu
                produto ou serviço.
              </Text>
              <RowButtons>
                <DefaultButton
                  onClick={(e) => {
                    handleClick(null, null)
                  }}
                >
                  Conhecer serviços
                </DefaultButton>
                <Link
                  activeClass="active"
                  to="form"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                >
                  <DefaultButton white={true}>Fale com a gente</DefaultButton>
                </Link>
              </RowButtons>
            </div>
            <ImagePrincipal
              src={OutdoorImg}
              alt="Outdoor Lanceiros"
              longdesc="Vetor digital contendo duas pessoas negras, uma mulher e um homem, um quadro branco ao fundo com post-its coloridos espalhados, a mulher esta à esquerda colando um post-it amarelo no quadro, e o homem centralizado, colando um post-it cinza."
            />
          </Content>
        </Outdoor>
        <Element name="manifest">
          <DiscoverLancers>
            <TitleDiscover>Descubra Lanceires</TitleDiscover>
            <div></div>
            <AdjustCard right={true}>
              <CardLancers
                title="Manifesto Lanceires"
                text="Somos uma empresa ativista que busca caminhos para ascender no ramo da tecnologia."
              />
            </AdjustCard>
            <AdjustCard>
              <CardLancers
                title="Nosso propósito cultural"
                text="Estar atuando em frente as barreiras raciais e sociais que a população preta confronta, abrindo caminhos, visibilidade e oportunidades para ascensão do jovem prete."
              />
            </AdjustCard>
          </DiscoverLancers>
        </Element>
        <Outdoor>
          <Title purple={true}>
            Construimos soluções com <br /> excelência para o seu <br /> produto
            sair do papel
          </Title>
          <Content>
            <div className="container">
              <Text>
                Você sabe onde quer chegar com seu produto e nós entendemos do
                assunto para seguir o caminho certo com você!
              </Text>
              <RowButtons none={true}>
                <Link
                  activeClass="active"
                  to="three"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <DefaultButton>Descubra nossas soluções</DefaultButton>
                </Link>
              </RowButtons>
            </div>
            <ImagePrincipal
              purple={true}
              src={Nave}
              alt="arte digital com título: O nosso processo em toda a jornada do seu produto"
              longdesc="Vetor digital contendo uma pessoa negra com pequenas folhas amarelas saindo das suas costas pela direta, sentada no canto direito da imagem. À direita da imagem tem um foguete na vertical com tonalidades de cinza e o vidro amarelo centralizado, com folhagens amarelas saindo do lado esquerdo do foguete."
            />
            <ImageMobile
              src={Nave}
              alt="arte digital com título: O nosso processo em toda a jornada do seu produto"
              longdesc="Vetor digital contendo uma pessoa negra com pequenas folhas amarelas saindo das suas costas pela direta, sentada no canto direito da imagem. À direita da imagem tem um foguete na vertical com tonalidades de cinza e o vidro amarelo centralizado, com folhagens amarelas saindo do lado esquerdo do foguete."
            />
            <div></div>
            <Title secondtitle={true} purple={true}>
              O nosso processo de <br /> inovação em toda a jornada <br /> do
              seu produto
            </Title>
            <div className="finalButtton">
              <Link
                activeClass="active"
                to="three"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <DefaultButton>Quero saber como</DefaultButton>
              </Link>
            </div>
          </Content>
        </Outdoor>
        <Experience>
          <Element name="sectors">
            <Title white={true} margin={true}>
              Nossas Expertises
            </Title>
            <CardContent>
              <Card>
                <Option>
                  <img src={Pen} alt="Pen" />
                  <Text bold={true}>Comunicação e Design</Text>
                </Option>
                <DefaultButton
                  onClick={(e) => handleClick(2, [false, false, true])}
                  card={true}
                >
                  Ver serviço
                </DefaultButton>
              </Card>
              <Card>
                <Option>
                  <img src={UiUx} alt="UiUx" />
                  <Text bold={true}>UX/UI Design</Text>
                </Option>
                <DefaultButton
                  onClick={(e) => handleClick(0, [true, false, false])}
                  card={true}
                >
                  Ver serviço
                </DefaultButton>
              </Card>
              <Card>
                <Option>
                  <img src={Code} alt="Code" />
                  <Text bold={true}>Desenvolvimento</Text>
                </Option>
                <DefaultButton
                  onClick={(e) => handleClick(1, [false, true, false])}
                  card={true}
                >
                  Ver serviço
                </DefaultButton>
              </Card>
            </CardContent>
          </Element>
        </Experience>
        <Element name="three">
          <Outdoor className="introduction" style={{ marginTop: '1vh' }}>
            <Title purple={true}>
              Descubra como desenvolvemos inovação para o seu produto
            </Title>
            <Content>
              <div>
                <Text>
                  Conheça nosso processo de trabalho e descubra como podemos
                  tirar seu produto do papel
                </Text>
              </div>
              <div>
                <ImagePrincipal
                  className="imgPrincipal"
                  src={Case}
                  alt="Arte digital representando fluxo de telas de aplicativos."
                  longdesc="Vetor digital contendo três esboços de telas de aplicativos, seguindo um fluxo da esquerda para direita - entre cada esboço há uma flecha indicando a próxima tela -, a direta da imagem segurando o terceiro esboço de tela, tem uma mulher negra em pé, com uma camiseta amarela e macacão escuro."
                />
              </div>
              <div>
                <ImageMobile
                  style={{ height: '27vw', marginTop: '10%' }}
                  className="imgPrincipal"
                  src={Case}
                  alt="Arte digital representando fluxo de telas de aplicativos."
                  longdesc="Vetor digital contendo três esboços de telas de aplicativos, seguindo um fluxo da esquerda para direita - entre cada esboço há uma flecha indicando a próxima tela -, a direta da imagem segurando o terceiro esboço de tela, tem uma mulher negra em pé, com uma camiseta amarela e macacão escuro."
                />
              </div>
            </Content>
          </Outdoor>
          <ThreeContent>
            <Branch className="Ideia" left={true}>
              <ThreeTextBox>
                <Title>1. Ideia</Title>
                <Title semi={true}>
                  Entendemos bem o problema e temos ideias de como resolver.
                </Title>
                <Text three={true}>
                  Nesta fase, a ideia pode ser defendida apenas com uma
                  apresentação: Briefing
                </Text>
              </ThreeTextBox>
            </Branch>
            <EmpityBox left={true} className="n1">
              <Ball left={true} />
            </EmpityBox>
            <Branch className="Prototipo" left={false}>
              <ThreeTextBox right={true}>
                <Title>2. Protótipo</Title>
                <Title semi={true}>
                  Queremos avaliar a melhor forma de entregar a solução.
                </Title>
                <Text three={true}>
                  Nesse processo exploramos visualmente a ideia apresentada no
                  Briefing, buscando identificar as principais funcionalidades
                  do seu produto.
                </Text>
              </ThreeTextBox>
            </Branch>
            <EmpityBox left={false} className="n2">
              <Ball />
            </EmpityBox>
            <Branch className="Mvp" left={true}>
              <ThreeTextBox>
                <Title>
                  3. MVP{' '}
                  <span
                    style={{
                      color: 'black',
                      fontSize: '1.9vw',
                      fontWeight: '700',
                    }}
                  >
                    (mínimo produto viável)
                  </span>
                </Title>
                <Title semi={true}>
                  Vamos transformar o protótipo escolhido em um produto
                  evolutivo.
                </Title>
                <Text three={true} style={{ width: '80%' }}>
                  Uma solução funcional que permite executar o essencial do
                  processo.
                </Text>
              </ThreeTextBox>
            </Branch>
            <EmpityBox left={true} className="n3">
              <Ball left={true} />
            </EmpityBox>
            <Branch className="Mercado" right={true}>
              <ThreeTextBox right={true} n4={true}>
                <Title>4. Mercado</Title>
                <Title semi={true}>
                  Um produto maduro, visando ganhar escala.
                </Title>
                <Text three={true}>
                  Caso a resposta do mercado não seja a esperada, volta-se à
                  ideia.
                </Text>
              </ThreeTextBox>
            </Branch>
            <EmpityBox left={false} className="n4">
              <Ball />
            </EmpityBox>
          </ThreeContent>
        </Element>
        <Element name="form">
      <FormContainer>
        <DiscoverLancers form={true}>
          <Main form={true}>
            <ImgLancer form={true} src={ImageLancer} alt="Logomarca Lanceiros"/>
            <TitleDiscover style={{ marginTop: '5%' }}>
              Explore a inovação para o seu produto digital.
            </TitleDiscover>
          </Main>
            <Form />
        </DiscoverLancers>
        </FormContainer>
      </Element>
      </Main>
      
      <Footer landing={true} />
    </>
  )
}
