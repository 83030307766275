import styled from 'styled-components'
export const CardContainer = styled.div`
  display: ${(props) => (props.carousel ? 'none' : 'grid')};
  grid-template-columns: 1fr 1fr 1fr;

  padding-top: 5%;
  padding-bottom: 5%;

  @media (max-width: 720px) {
    display: ${(props) =>
      props.carousel ? 'block' : props.directors ? 'grid' : 'none'};
    grid-template-columns: 1fr 1fr;

    padding-left: ${(props) => (props.carousel ? '22%' : '0')};
    padding-right: ${(props) => (props.carousel ? '22%' : '0')};
    .carousel {
      display: block;
      position: relative;
    }
  }
`
export const AreasTitle = styled.div`
  padding-top: ${(props) => (props.secondTitle ? '30%' : '3%')};
  padding-bottom: ${(props) => (props.secondTitle ? '10%' : '3%')};

  @media (max-width: 720px) {
    padding-top: ${(props) => (props.secondTitle ? '5%' : '80px')};
    padding-bottom: 5%;
  }
`
export const OutdoorAreas = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 720px) {
    display: block;
  }
`
