import styled from 'styled-components'

export const Main = styled.div`
  position: fixed;
  width: 100%;
  #sidebar {
    height: auto;
    display: grid;
    width: 70%;
    align-items: center;

    border-radius: 7px;
    transition: 850ms;
    width: 100%;

    padding-bottom: 3%;

  }
  .link {
    padding-bottom: 3%;
    padding-top: 3%;
    padding-left: 5%;
    text-decoration: none;
    font-family: 'IBM Plex Sans';
    font-size: 3vw;
    font-style: normal;
    font-weight: 700;
    line-height: 5vw;
    letter-spacing: 0em;
    text-align: left;
    color: #e5e5e5;
  }
  .midia {
    height: auto;
    padding-bottom: 3%;
    padding-left: 5%;
  }
  .midia div {
    margin-left: -1%;
  }
  .midia img {
    height: 7vw;
    width: 10vw;
  }
  .midia h1 {
    font-size: 5vw;
  }
  .link button {
    border: none;
    background: #e2c033;
    border-radius: 3vw;

    padding-top: 2%;
    padding-left: 7%;
    padding-bottom: 2%;
    padding-right: 7%;

    font-family: 'Roboto';
    font-size: 4vw;
    font-style: normal;
    font-weight: 700;
    line-height: 5vw;
    letter-spacing: 0em;
    text-align: center;
  }

  @media (max-width: 480px) {
    .sidebar {
      top: 20vw;
    }
  }
`
