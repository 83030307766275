import styled from 'styled-components'

export const Main = styled.div`
  width: 100%;
  height: auto;
  background-color: #ffffff;
`
export const Row = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.second ? '1fr 1fr 1fr' : '1fr')};
`
export const Title = styled.h1`
  color: #410b9e;
  font-family: 'IBM Plex Sans';
  font-size: 3vw;
  font-style: normal;
  font-weight: 500;
  line-height: 2.5vw;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10%;
  margin-bottom: 5%;
`
export const Card = styled.div`
  background-color: #2e2e2e;
  width: auto;
  border-radius: 1vw;
  margin-bottom: 5%;
  margin-right: 5%;
  margin-left: 5%;

  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  height: auto;
  text-align: center;

  @media (max-width: 720px) {
    padding-bottom: 1%;
    height: 100%;
    padding-top: 7%;
  }
`
export const PersoImg = styled.img`
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 5%;
  margin-left: 5%;

  height: 15vw;

  @media (max-width: 720px) {
    height: 25vw;
  }
`
export const Body = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
`
export const Name = styled.h1`
  color: #ff9400;
  font-family: 'IBM Plex Sans';
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 2vw;
  letter-spacing: 0em;
  text-align: center;
  @media (max-width: 720px) {
    font-size: 4vw;
    line-height: 5vw;
  }
`
export const Office = styled.p`
  color: ${(props) => (props.director ? '#000' : '#fff')};
  font-family: 'IBM Plex Sans';
  font-size: ${(props) => (props.director ? '2vw' : '1.5vw')};
  font-style: normal;
  font-weight: ${(props) => (props.director ? 'bold' : '500')};
  line-height: 2vw;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 5%;
  padding-right: 5%;
  @media (max-width: 720px) {
    font-size: 3vw;
    line-height: 4vw;
  }
`
export const Logo = styled.img`
  height: 2vw;
  @media (max-width: 720px) {
    height: 6vw;
  }
`
