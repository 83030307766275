import React from 'react'
import Areas from './pages/Areas'
import Landing from './pages/Landing'
import Selected from './pages/Selected'
import PageNotFound from './pages/PageNotFound'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Landing} />
      {/* <Route exact path="/Cta" component={Selected} /> */}
      <Route exact path="/areas" component={Areas} />
      <Route component={PageNotFound} />
    </Switch>
  </BrowserRouter>
)

export default Routes
