import { Link } from 'react-scroll'
import { useHistory } from 'react-router-dom'
import twitter from '../../assets/Footer/Twitter.svg'
import facebook from '../../assets/Footer/facebook.svg'
import linkedin from '../../assets/Footer/linkedin.svg'
import instagram from '../../assets/Footer/instagram.svg'
import LogoDesktop from '../../assets/Footer/Logo-desktop.svg'
import {
  Title,
  Main,
  Column,
  Subtitle,
  Text,
  Midias,
  Row,
  Logo,
  Email,
} from './Footer'

export default function Footer({ landing, infoAreas, setInfo, setActive }) {
  const history = useHistory()

  function handleClick(index, active) {
    if (landing) {
      history.push('/areas', { index: index, active: active })
    } else {
      setInfo(infoAreas[index])
      setActive(active)
      window.scrollTo(0, 0)
    }
  }

  function handleClickBrand() {
    if (!landing) {
      history.push('/')
      window.scrollTo(470, 470)
    }
  }

  return (
    <Main>
      <Row>
        <Title>
          Um novo <br /> espaço <br /> para seu <br /> produto <br /> manifestar
        </Title>
        <span></span>
        <Column>
          <Subtitle
            onClick={(e) => {
              handleClick(null, null)
            }}
          >
            SERVIÇOS
          </Subtitle>
          <ul className="conteúdos">
            <Link
              style={{ textDecoration: 'none' }}
              href="/"
              activeClass="active"
              onClick={(e) => {
                handleClick(2, [false, false, true])
              }}
            >
              <Text>Comunicação & Design</Text>
            </Link>
            <Link
              style={{ textDecoration: 'none' }}
              href="/"
              onClick={(e) => {
                handleClick(0, [true, false, false])
              }}
            >
              <Text>UX|UI Design</Text>
            </Link>
            <Link
              style={{ textDecoration: 'none' }}
              href="/"
              onClick={(e) => {
                handleClick(1, [false, true, false])
              }}
            >
              <Text>Desenvolvimento Dev</Text>
            </Link>
          </ul>
        </Column>

        <Column>
          <Subtitle>MARCA</Subtitle>
          <ul className="conteúdos">
            <Link
              onClick={(e) => {
                handleClickBrand()
              }}
              style={{ textDecoration: 'none' }}
              to="manifest"
              href="/"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              <Text>Manifesto</Text>
            </Link>
            <Link
              onClick={(e) => {
                handleClickBrand()
              }}
              style={{ textDecoration: 'none' }}
              to="manifest"
              href="/"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              <Text>Propósito</Text>
            </Link>
          </ul>
        </Column>
      </Row>

      <Row second={true}>
        <Email mobile={true}> redes@lanceiros.com</Email>
        <Midias>
          <div>
            <p>
              Por onde nos <br /> encontrar por aí
            </p>
            <a
              style={{ textDecoration: 'none' }}
              href="https://www.facebook.com/lanceirostech/"
            >
              <img
                alt="Logo Facebook"
                src={facebook}
                style={{ marginLeft: '0%' }}
              />
            </a>
            <a
              style={{ textDecoration: 'none' }}
              href="https://www.instagram.com/lanceirostech/"
            >
              <img alt="Logo Instagram" src={instagram} />
            </a>
            <a
              style={{ textDecoration: 'none' }}
              href="https://www.linkedin.com/company/lanceiros-tech/"
            >
              <img alt="Logo Linkedin" src={linkedin} />
            </a>
            <a
              style={{ textDecoration: 'none' }}
              href="https://twitter.com/lanceirostech/"
            >
              <img alt="Logo Twitter" src={twitter} />
            </a>
          </div>
        </Midias>
        <Email> redes@lanceiros.com</Email>
        <Logo>
          <div>
            <img alt="Logo Lanceiros" src={LogoDesktop} />
          </div>
        </Logo>
      </Row>
    </Main>
  )
}
