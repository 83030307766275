import styled from 'styled-components'

export const Input = styled.input`
  background: #4b4343;
  border-radius: 0.5vw;

  color: #706b6b;
  background: #d3cdcd;
  padding-bottom: 3%;
  padding-top: 3%;
  padding-left: ${(props)=>(props.row ? '4%' : (props.firstRow? '4%' : '2%'))};
  width: ${(props) => (props.row ? '95%' : '100%')};
  margin-bottom: 3%;
  background: #4b4343;

  margin-left: ${(props) => (props.row ? '5%' : '0')};
  margin-top: 1%;

  font-family: 'Roboto';
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2vw;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 720px) {
    font-size: 4vw;
    border-radius: 2vw;
    width: 100%;
    margin-left: 0%;
  }
`
export const FormContainer = styled.form`
  display: grid;
  padding-top: 4%;
  padding-right: 5%;
  padding-left: 0%;
  margin-bottom: 5%;

  .row-btn {
  }

  @media (max-width: 720px) {
    padding: 5%;
  }
`
export const TextArea = styled.textarea`
  background: #4b4343;
  color: #706b6b;
  border-radius: 0.6vw;
  border: 0;
  outline: none;
  height: 50%;
  padding-top: 3%;
  padding-left: 2%;
  resize: none;

  font-family: 'Roboto';
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 2vw;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 720px) {
    font-size: 4vw;
    height: 100%;
    padding-bottom: 3%;
  }
`
export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 720px) {
    display: block;
  }
`
