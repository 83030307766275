import { cardsData } from './cardsData'
import DevsImg from '../assets/Areas/Devs.svg'
import DesignImg from '../assets/Areas/Design.svg'
import CommunicationImg from '../assets/Areas/Communication.svg'

export const infoAreas = [
  {
    index: 0,
    name: 'UX/UI Design',
    first_text:
      'UX/UI está em todo nosso processo ao desenvolver o seu produto, exploramos suas ideias em busca de melhorar a experiência dos usuários, identificando funcionalidades ágeis para uma melhor usabilidade. Nosso processo de inovação tem como objetivo gerar autonomia na ideia do seu produto ou serviço digital, entendendo cenário, quem são seus usuários, necessidade, qual problema ele resolve, e como ele propõe valor financeiro para sua empresa.',
    principal_img: DesignImg,
    implementation: [
      {
        index: 0,
        title: 'Design sprint',
        text: 'O Design Sprint traz agilidade em nossas soluções de produto, concebendo funcionalidades inovadoras e colaborativas para dentro da empresa.  ',
      },
      {
        index: 1,
        title: 'Design thinking',
        text: 'Nosso processo thinking é uma abordagem que busca mapear e mesclar a experiência e solucionar problemas resolutivos.',
      },
    ],
    card_text: 'Nosso time de UX/UI Design',
    cards: cardsData[0],
  },

  {
    index: 1,
    name: 'Desenvolvimento',
    first_text:
      'O Desenvolvimento é o processo que dá vida ao seu produto. Executamos suas ideias de inovação, conferindo maior autonomia do seu produto ou serviço digital.',
    principal_img: DevsImg,
    implementation: [
      {
        index: 0,
        title: 'Desenvolvimento',
        text: 'Nossa concepção é colocar a ideia à prática, desenvolvendo tecnologia voltada à construção de sites, aplicativos, softwares, bancos de dados.',
      },
      {
        index: 1,
        title: 'Aplicações Web',
        text: 'Trabalhamos com usabilidade para solucionar. Solucionamos processos manuais, gestão de documentos, cadastros, para uma usabilidade fácil e eficaz.',
      },
    ],
    card_text: 'Nosso time de Desenvolvimento ',
    cards: cardsData[2],
  },

  {
    index: 2,
    name: 'Comunicação & Design ',
    first_text:
      'A nossa manifestação visual está no design, desenvolvido com excelência e muita paixão. Em nossa área de Comunicação & Design, a comunicação potencializa nossos produtos, entregando personalidade, criatividade e transformando ideias tiradas do papel em projetos eficientes. ',
    principal_img: CommunicationImg,
    implementation: [
      {
        index: 0,
        title: 'Brand management',
        text: 'Nossa metodologia dentro da gestão de marca está no processo de construção e percepção de identidade, buscando entender as motivações e ações do consumidor de seu produto ou serviço.',
      },
      {
        index: 1,
        title: 'Name Foundry',
        text: 'Método desenvolvido para pesquisa e criação de marca visual com o objetivo registrar e identificar a personalidade do seu produto ou empresa.',
      },
    ],
    card_text: 'Nosso time de comunicadores ',
    cards: cardsData[1],
  },
]
