import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-scroll'
import { useHistory } from 'react-router-dom'
import { Main, HeaderRight } from './Header.js'
import close from '../../assets/Header/close.svg'
import menu from '../../assets/Header/menu-lateral.svg'
import { sidebarData } from '../../constants/sidebarData'
import logoMobile from '../../assets/Header/logo-mobile.svg'
import logoDesktop from '../../assets/Header/logo-desktop.svg'

export default function Header(props) {
  const [sidebar, setSidebar] = React.useState(false)
  const history = useHistory()

  function showSidebar() {
    setSidebar(!sidebar)
  }

  function handleClick() {
    history.push('/')
    window.scrollTo(450, 450)
  }

  return (
    <Main>
      <div className="logo">
        <a href="/">
          <img id="logoDesktop" src={logoDesktop} alt="Logo Lanceios" />
        </a>
        <a href="/">
          <img id="logoMobile" src={logoMobile} alt="Logo Lanceiros" />
        </a>
      </div>

      {props.areas ? (
        <HeaderRight areas={true}>
          {sidebarData.map((data) =>
            data.id === 0 ? (
              <Link
                href="/"
                onClick={(e) => {
                  handleClick()
                }}
                key={data.id}
                activeClass="active"
                to={data.path}
                spy={true}
                smooth={true}
                offset={data.offset}
                duration={500}
              >
                {data.text}
              </Link>
            ) : (
              data.id !== 1 && (
                <Link
                  key={data.id}
                  href={data.href}
                  activeClass="active"
                  to={data.path}
                  spy={true}
                  smooth={true}
                  offset={data.offset}
                  duration={500}
                >
                  {data.text}
                </Link>
              )
            )
          )}
          <Link
            to="form"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            activeClass="active"
            id="contactButton"
          >
            Faça Contato
          </Link>
        </HeaderRight>
      ) : (
        <HeaderRight>
          {sidebarData.map((data) => (
            <Link
              key={data.id}
              href="/"
              activeClass="active"
              to={data.path}
              spy={true}
              smooth={true}
              offset={data.offset}
              duration={500}
            >
              {data.text}
            </Link>
          ))}
          <Link
            to="form"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            activeClass="active"
            id="contactButton"
          >
            Faça Contato
          </Link>
        </HeaderRight>
      )}

      <nav className="sidebar">
        {sidebar ? (
          <div
            style={{
              marginTop: '20%',
              marginRight: '30%',
              marginBottom: '30%',
            }}
          >
            <button onClick={showSidebar}>
              <img style={{ height: '11vw' }} alt="menu-ifé" src={close} />
            </button>
          </div>
        ) : (
          <button onClick={showSidebar}>
            <img alt="menu-ifé" src={menu} />
          </button>
        )}
      </nav>

      {sidebar && (
        <div id="sidebarMenu">
          <Sidebar setSidebar={setSidebar} areas={props.areas} />
        </div>
      )}
    </Main>
  )
}
