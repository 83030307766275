import styled from 'styled-components'

export const Main = styled.div`
  .midias {
    margin-left: 10%;
    display: grid;
    grid-template-rows: 1fr 1fr;
  }
  .midias div {
    display: inline-block;
  }

  .midias h1 {
    color: #a7a5a9;

    font-family: 'Inria Sans';
    font-size: 3.8vw;
    font-style: normal;
    font-weight: 400;
    line-height: 4vw;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: 2%;
  }
  .midias img {
    width: 7vw;
    height: 4.5vw;
    padding-right: 15%;
    padding-left: 15%;
  }

  #logoMobile {
    display: none;
  }
  @media (max-width: 720px) {
    .midias h1 {
      font-size: 6vw;
    }
    .midias img {
      height: 9vw;
      width: 10vw;
    }
  }
`
