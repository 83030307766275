import React from 'react'
import { Main } from './Midias.js'
import { logosMidias } from '../../constants/logosMidias'

export default function Midias() {
  return (
    <Main>
      <div className="midias">
        <h1> Nossas Redes</h1>
        <div>
          {logosMidias.map((logo) => (
            <div key={logo.id + 1}>
              <a href={logo.link}>
                <img src={logo.img} alt="" />
              </a>
            </div>
          ))}
        </div>
      </div>
    </Main>
  )
}
