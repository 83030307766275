import styled from 'styled-components'

export const Card = styled.div`
  display: block;
  background-color: #000000;
  margin-right: -2%;
  margin-left: -2%;
  border-radius: 1vw;
  height: 100%;

  align-items: center;
  text-align: center;

  padding-top: 3%;
  padding-bottom: 6%;

  @media (max-width: 720px) {
    text-align: left;
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 3%;
    margin-bottom: 5%;
    border-radius: 2vw;
  }
`

export const ImgLancer = styled.img`
  height: 7vw;

  @media (max-width: 720px) {
    margin-top: 3%;
    margin-left: 10%;
    height: ${(props) => (props.form ? '20vw' : '15vw')};
    margin-right: ${(props) => (props.form ? '69%' : '0')};
  }
`
export const CardTitle = styled.h1`
  padding-top: 5%;
  padding-right: 15%;
  padding-left: 10%;
  font-family: 'IBM Plex Sans';
  font-size: 3vw;
  font-style: normal;
  font-weight: 500;
  line-height: 3.5vw;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 720px) {
    font-size: 5vw;
    line-height: 5.7vw;
    font-weight: 700;
    margin-right: 25%;
  }
`
export const CardText = styled.p`
  color: #a3a1a1;

  font-family: 'Roboto';
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 2vw;
  letter-spacing: 0em;
  text-align: left;

  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;

  @media (max-width: 720px) {
    font-size: 3vw;
    line-height: 4vw;
  }
`
