import React from 'react'
import { useMail } from '../../hooks/useMail'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import { Input, FormContainer, TextArea, Row } from './Form'
import { DefaultButton } from '../../pages/Landing/LandingStyles'

export default function Form(props) {
  const mail = useMail()

  const [data, setData] = React.useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    message: '',
  })
  function handleChange({ target }) {
    const { id, value } = target
    setData({ ...data, [id]: value })
  }
  function handleSubmit(event) {
    event.preventDefault()
    for (let field in data) {
      if (data[field] === '') {
        toast.error('Todos os campos são obrigatórios')
        return
      }
    }
    mail.send(data)
    toast.success('Mensagem enviada com sucesso!')
    setData({ name: '', email: '', phone: '', company: '', message: '' })
  }

  return (
    <FormContainer onSubmit={handleSubmit}>
      <ToastContainer />
      <Input
        value={data.name}
        type="text"
        id="name"
        placeholder="Digite seu nome completo"
        onChange={handleChange}
      />
      <Row>
        <Input
          firstRow={true}
          value={data.email}
          type="email"
          id="email"
          placeholder="Email"
          onChange={handleChange}
        />
        <Input
          row={true}
          value={data.phone}
          type="tel"
          id="phone"
          placeholder="WhatsApp"
          onChange={handleChange}
        />
      </Row>
      <Input
        value={data.company}
        type="tel"
        id="company"
        placeholder="Empresa"
        onChange={handleChange}
      />
      <TextArea
        rows="11"
        value={data.message}
        type="text"
        id="message"
        placeholder="Digite sua mensagem aqui..."
        onChange={handleChange}
      />
      {/* <div className="row-btn"> */}
      <DefaultButton form={true}>Enviar</DefaultButton>
      {/* </div> */}
    </FormContainer>
  )
}
