import TwitImg from '../assets/Footer/Twitter.svg'
import FaceImg from '../assets/Footer/facebook.svg'
import LinkImg from '../assets/Footer/linkedin.svg'
import InstaImg from '../assets/Footer/instagram.svg'

export const logosMidias = [
  { id: 0, img: FaceImg, link: 'https://www.facebook.com/lanceirostech/' },
  { id: 1, img: InstaImg, link: 'https://www.instagram.com/lanceirostech/' },
  {
    id: 2,
    img: LinkImg,
    link: 'https://www.linkedin.com/company/lanceiros-tech/',
  },
  { id: 3, img: TwitImg, link: 'https://twitter.com/lanceirostech/' },
]
