import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        color: white;
    }
    html, body, #root{
        width: 100%;
        height: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    *, button, input{
        border: 0;
        background: none;
        font: 400 1.8rem/1 'Open Sans', sans-serif;
        outline: none;
    }
    html{
        background: white;
    }
    :root{
        font-size: 62.5%;
    }
    ::-webkit-scrollbar{
        width: 15px;
    }
    ::-webkit-scrollbar-track{
        background-color: black ;
    }
    ::-webkit-scrollbar-thumb{
        background-color: #FF9400 ;
        border-radius: 5px;
        height:100px;
    }
`
