import styled from 'styled-components';

export const Main = styled.div`


    width: 100%;
    height: auto;
    background-color: #000000;
    bottom: 0;
    
`
    
export const Title  = styled.h1`

    color: #ffffff;
    font-family: 'IBM Plex Sans';
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 500;
    line-height:3vw;
    letter-spacing: 0em;
    text-align: left;
    margin-top:10%;
    margin-left:10%;

    @media(max-width: 720px){
        br {
            display : none;
        }
        font-size : 5vw;
        line-height : 6vw;
    }
`
export const Column = styled.div`

    @media(max-width: 720px){
    padding-right: 10%;
    padding-left: 10%;
    margin-top : 5%;
    }


`


export const Subtitle = styled.h4`
    color:#FF9400;
    font-family: 'IBM Plex Sans';
    font-size: 1.7vw;
    font-style: normal;
    font-weight: 700;
    line-height: 2.8vw;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 10%;

    @media (max-width: 720px) {
        font-size : 5vw;
        line-height : 6vw;
    }
    

`


export const Text = styled.p`
    color: #A3A1A1;
    font-family: 'IBM Plex Sans';
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 700;
    line-height: 1.8vw;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 10%;
    margin-bottom:10%;

    @media (max-width: 720px) {
        font-size : 4vw;
        line-height: 5vw;
    }

   `



export const Midias = styled.div`
    display: inline;
    justify-content: left;
    align-items: left;
    margin-left:7%;

        p {
        color:#A3A1A1;
        font-family:'IBM Plex Sans';
        font-size: 1.1vw;
        text-align: left;
        margin-left:3%;
        margin-bottom:2%;
            

}
    img {
        height:2.5vw;
        margin-left:1.75%;

    }

    @media(max-width: 720px){
       
        p {
            font-size: 6.5vw;
            margin-left : 0;
           
        }
        img {
            height : 10vw;
            margin-top :3%;
            ;
        }
        div {
            margin-left : 5%;
        }
    }

`

export const Logo = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: right;
    img {
        height:6.5vw;

    }
    @media(max-width: 720px){
        align-items: center;
        justify-content: left;
        img {
            height : 20vw;
        }
        margin-top : 3%;
        margin-left : 5%;
    }


`
export const Email = styled.h2`
    display : ${props => props.mobile ? 'none' : 'flex'};
    color: #FFFFFF;
    font-family: 'IBM Plex Sans';
    font-size: 1.3vw;       
    font-weight:600;
    line-height: 10vw;
    letter-spacing: 0em;
    text-align: center;
    margin-right:2%;

    @media (max-width: 720px) {
        display : ${props => props.mobile ? 'flex' : 'none'};
        margin-left : 5%;
        font-size: 5vw; 

    }
`

export const Row = styled.div`
    margin-top :  ${props => props.second ? '5%' : '0'};;
    display:grid;
    grid-template-columns: ${props => props.second ? '1fr 1fr 1fr' : '1.5fr 1fr 1fr 1fr'};

    @media (max-width: 720px) {
        display: ${props => props.second ? 'block' : 'grid'};;
        grid-template-columns :  1fr 1fr;
    }

    
       

`
