import styled from 'styled-components'

export const Main = styled.header`
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9999;

  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 3fr;
  width: 100%;
  height: 85px;

  background-color: #000;

  #sidebarMenu {
    display: none;
  }
  .sidebar {
    display: none;
    margin-right: 20%;
    margin-top: 10%;
  }
  .sidebar button {
    background: none;
    border: 0;
    outline: none;
  }
  .sidebar img {
    height: 13vw;
  }
  #logoDesktop {
    display: flex;
    height: 4vw;
    margin-top: 0.6%;
    margin-bottom: 0.6%;
    cursor: pointer;
  }
  #logoMobile {
    display: none;
  }

  @media (max-width: 720px) {
    grid-template-columns: 1fr 0.1fr;
    padding-top: 0;
    padding-bottom: 0;

    #sidebarMenu {
      display: block;
    }
    #logoDesktop {
      display: none;
    }
    #logoMobile {
      display: flex;
      height: 13vw;
    }

    .sidebar {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .logo {
      justify-content: center;
      align-items: center;
    }
  }
`

export const HeaderRight = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.areas ? '1fr 0.3fr' : '1fr 0.5fr 0.5fr'};
  justify-items: flex-end;
  justify-content: end;
  align-items: center;
  color: white;

  a {
    font-family: 'IBM Plex Sans';
    font-size: 1vw;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
  }

  #contactButton {
    color: #000;
    font-family: 'Roboto';
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 600;
    line-height: 2vw;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 30px;
    height: 60px;
    padding: 0px 15px;
    display: flex;
    align-items: center;

    width: auto;
    cursor: pointer;

    background-color: #ff9400;
    border-radius: 0.8vw;
  }

  @media (max-width: 720px) {
    display: none;
  }
`
