import React from 'react'
import Midias from '../Midias'
import { Link } from 'react-scroll'
import { Main } from './Sidebar.js'
import { useHistory } from 'react-router-dom'
import { sidebarData } from '../../constants/sidebarData'
import { DefaultButton } from '../../pages/Landing/LandingStyles'

export default function Sidebar({ areas, setSidebar }) {
  const history = useHistory()

  function handleClick() {
    history.push('/')
  }
  return (
    <Main>
      <div style={{ background: '#000' }}>
        {areas ? (
          <div id="sidebar" className="animate__animated animate__fadeInLeft">
            <button
              onClick={(e) => {
                handleClick()
              }}
              className="link"
            >
              HOME
            </button>
            <div style={{ marginLeft: '3%', marginTop: '1%' }}>
              <Link
                onClick={() => setSidebar(false)}
                href="/"
                activeClass="active"
                to="form"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                style={{ textDecoration: 'none' }}
              >
                <DefaultButton style={{ fontSize: '4vw' }}>
                  Faça Contato
                </DefaultButton>
              </Link>
            </div>
            <div className="midia">
              <Midias />
            </div>
          </div>
        ) : (
          <div id="sidebar" className="animate__animated animate__fadeInLeft">
            {sidebarData.map((data) => (
              <Link
                onClick={() => setSidebar(false)}
                className="link"
                key={data.id}
                href="/"
                activeClass="active"
                to={data.path}
                spy={true}
                smooth={true}
                offset={data.offset}
                duration={500}
              >
                {data.text}
              </Link>
            ))}
            <div style={{ marginLeft: '3%', marginTop: '1%' }}>
              <Link
                onClick={() => setSidebar(false)}
                href="/"
                activeClass="active"
                to="form"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                style={{ textDecoration: 'none' }}
              >
                <DefaultButton style={{ fontSize: '4vw' }}>
                  Faça Contato
                </DefaultButton>
              </Link>
            </div>
            <div className="midia">
              <Midias />
            </div>
          </div>
        )}
      </div>
    </Main>
  )
}
