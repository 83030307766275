import React from 'react'
import { Element } from 'react-scroll'
import Token from '../../components/Token'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { infoAreas } from '../../constants/infoAreas'
import CardProfile from '../../components/CardProfile'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { directorsData } from '../../constants/directorsData'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'
import { CardContainer, AreasTitle, OutdoorAreas } from './Areas'
import ImageLancer from '../../assets/Landing/Lancer.svg'
import {
  Title,
  Main,
  Text,
  ImageMobile,
  ImagePrincipal,
  DiscoverLancers,
  TitleDiscover,
} from '../../pages/Landing/LandingStyles'
import {
  Container,
  RowButtons,
  DefaultButton,
  Click,
} from '../../pages/Selected/SelectedStyles'
import Form from '../../components/Form'
import { ImgLancer } from '../../components/CardLancers/CardLancersStyles'

export default function Areas(props) {
  const option =
    props.location.state && props.location.state.index
      ? props.location.state.index
      : 0
  const optionActive =
    props.location.state && props.location.state.active
      ? props.location.state.active
      : [true, false, false]
  const [info, setInfo] = React.useState(infoAreas[option])

  const [active, setActive] = React.useState(optionActive)

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header areas={true} />
      <Main areas={true}>
        <AreasTitle>
          <Title areas={true}>Conheça nossos serviços</Title>
        </AreasTitle>
        <Container>
          <RowButtons>
          
              <DefaultButton
                active={active[2]}
                style={{ paddingTop: '1%', paddingBottom: '1%' }}
                onClick={() => {
                  setInfo(infoAreas[2])
                  setActive([false, false, true])
                }}
              >
                Comunicação <br />& Design
              </DefaultButton>
            
            
              <DefaultButton
                active={active[0]}
                onClick={() => {
                  setInfo(infoAreas[0])
                  setActive([true, false, false])
                }}
              >
                UX/UI Design
              </DefaultButton>
            
            
              <DefaultButton
                active={active[1]}
                onClick={() => {
                  setInfo(infoAreas[1])
                  setActive([false, true, false])
                }}
              >
                Desenvolvimento
              </DefaultButton>
          
          </RowButtons>
        </Container>
        <Click>Clique em uma das áreas para saber mais</Click>
        <div>
          <div>
            <Title areas={true} purple={true}>
              {info.name}
            </Title>

            <Text areas={true}>{info.first_text}</Text>
          </div>
          <OutdoorAreas>
            <AreasTitle secondTitle={true}>
              <ImageMobile
                style={{ height: '35vw', paddingBottom: '3%' }}
                src={info.principal_img}
                alt={info.name}
                longdesc="Vetor digital contendo o esboço de uma tela, representando troca de mensagens. Há dois retângulos em destaque, onde cada um deles tem um círculo amarelo à esquerda, representando o avatar, e duas linhas cinza escuro à direita, representando o texto da mensagem."  
              />
              <Title areas={true} purple={true}>
                O que implementamos em seu produto
              </Title>
            </AreasTitle>
            <ImagePrincipal
              src={info.principal_img}
              alt={info.name}
              longdesc="Vetor digital contendo o esboço de uma tela, representando troca de mensagens. Há dois retângulos em destaque, onde cada um deles tem um círculo amarelo à esquerda, representando o avatar, e duas linhas cinza escuro à direita, representando o texto da mensagem."
            />
            {info.implementation.map((implementation) => (
              <div key={implementation.index}>
                <Title>{implementation.title}</Title>
                <Text>{implementation.text}</Text>
              </div>
            ))}
          </OutdoorAreas>
          <Title areas={true} purple={true}>
            {info.card_text}
          </Title>
          <CardContainer>
            {info.cards.map((card) => (
              <CardProfile props={card} />
            ))}
          </CardContainer>
          <CardContainer carousel={true}>
            <CarouselProvider
              className="carousel"
              naturalSlideWidth={100}
              isIntrinsicHeight={true}
              totalSlides={info.cards.length}
              isPlaying={true}
              interval={3000}
              step={1}
            >
              <Slider>
                {info.cards.map((card) => (
                  <Slide style={{ position: 'relative' }}>
                    <CardProfile props={card} />
                  </Slide>
                ))}
              </Slider>
            </CarouselProvider>
          </CardContainer>
          <Title areas={true}>Diretores Lanceiros Tech</Title>
          <CardContainer directors={true}>
            {directorsData.map((director) => (
              <CardProfile
                key={director.index}
                director={true}
                props={director}
              />
            ))}
          </CardContainer>
          <Element name="form">
          <DiscoverLancers form={true}>
          <Main form={true}>
            <ImgLancer form={true} src={ImageLancer} alt="Logomarca Lanceiros"/>
            <TitleDiscover style={{ marginTop: '5%' }}>
              Explore a inovação para o seu produto digital.
            </TitleDiscover>
          </Main>
            <Form />
        </DiscoverLancers>
          </Element>
        </div>
      </Main>
      <Footer infoAreas={infoAreas} setActive={setActive} setInfo={setInfo} />
    </>
  )
}
