import instagram from '../../assets/Areas/Midias/insta.svg'
import linkedin from '../../assets/Areas/Midias/linkedin.svg'
import { Card, Body, Name, Office, PersoImg, Logo } from './CardProfile'

export default function CardProfile({ props, director }) {
  return (
    <Card>
      <PersoImg alt="imgProfile" src={props.imgProfile} />
      <Body>
        <Name>{props.name}</Name>
        <Office director={director}>{props.office}</Office>
        <a href={props.link_linkedin}>
          <Logo alt="linkedin" src={linkedin} />
        </a>
        <a href={props.link_insta}>
          <Logo alt="instagram" src={instagram} />
        </a>
      </Body>
    </Card>
  )
}
