import styled from 'styled-components'

export const Main = styled.div``

export const Title = styled.div`
  color: #000;
  font-family: 'Roboto';
  font-size: 3vw;
  font-style: normal;
  font-weight: 500;
  line-height: 2.5vw;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 10%;
  margin-left: 10%;
  margin-bottom: 4%;
`

export const ComButton = styled.a`
  color: ${(props) => (props.purple ? '#FFFFFF' : '#000000')};
  font-family: 'Roboto';
  font-size: 2vw;
  font-style: normal;
  font-weight: 600;
  line-height: 2.5vw;
  text-align: left;
  vertical-align: top;
  text-decoration: none;
  background-color: #fff;
  border: 2%;
  padding-top: 1.25%;
  padding-bottom: 1.25%;
  border-radius: 0.75vw;
  margin-left: 20%;
  width: 62%;
  text-align: center;
  display: inline-block;
  background-color: #ffffff;

  @media (max-width: 720px) {
    background-color: #e6e6e6;
    color: #000;
    font-size: 4vw;
    padding-top: 5%;
    padding-bottom: 5%;
    line-height: 5vw;
    margin-left: 5%;
  }
`

export const DefaultButton = styled.a`
  color: ${(props) => (props.active ? '#FFFFFF' : '#000000')};
  font-family: 'Roboto';
  font-size: 2vw;
  font-style: normal;
  font-weight: 600;
  line-height: 2.5vw;
  vertical-align: top;
  text-decoration: none;
  background-color: #fff;
  border: 2%;
  padding-top: 5%;
  padding-bottom: 5%;
  border-radius: 0.75vw;
  margin-left: 20%;
  width: 70%;
  text-align: center;
  display: inline-block;
  background-color: ${(props) => (props.active ? '#410B9E' : '#FFFFFF')};

  cursor: pointer;

  :hover {
    background-color: #410b9e;
    color: #ffff;
  }
  @media (max-width: 720px) {
    background-color: #e6e6e6;
    color: ${(props) => (props.active ? '#410B9E' : '#000')};
    font-size: ${(props) => (props.active ? '4.5vw' : '4vw')};
    padding: 0px;
    display: flex;
    width: min-content;
    vertical-align: none;
    align-items: center;
    line-height: 5vw;
    margin-left: 0px;
    text-decoration: ${(props) => (props.active ? 'underline' : 'none')};

    :hover {
      background-color: #e6e6e6;
      color: #410b9e;
      text-decoration: underline;
      font-weight: 'bold';
    }
  }
`

export const RowButtons = styled.div`
  width: 100%;
  height: 10vw;
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 720px) {
    height: auto;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    
  }
`

export const Container = styled.div`
  width: 100%;
  height: 10vw;
  border-radius: 3px;
  background-color: #000000;
  @media (max-width: 720px) {
    background-color: #e6e6e6;
    height: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 10px

  }
`

export const Subtitle = styled.div`
  color: #410b9e;
  font-family: 'IBM Plex Sans';
  font-size: 3vw;
  font-style: normal;
  font-weight: 600;
  line-height: 2.5vw;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 7.5%;
  margin-left: 10%;
  margin-bottom: 4%;
  p {
    color: #000000;
    font-family: IBM Plex Sans;
    font-size: 2vw;
    font-style: normal;
    font-weight: 400;
    line-height: 3vw;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 2%;
  }
`

export const Click = styled.p`
  color: #000000;
  font-family: 'IBM Plex Sans';
  text-align: center;
  padding-top: 0.5vw;
  font-weight: bold;
  font-size: 1.5vw;
  padding-bottom: 3%;

  @media (max-width: 720px) {
    font-size: 3vw;
    padding-top: 5%;
  }
`