import OniImg from '../assets/Areas/Directors/Onilla.svg'
import FausImg from '../assets/Areas/Directors/Fausto.svg'
export const directorsData = [
  {
    index: 0,
    name: 'Fausto Vanin',
    office: 'Co-Founder e CTO',
    imgProfile: FausImg,
    link_linkedin: 'https://www.linkedin.com/in/faustovanin/',
    link_insta: 'https://www.instagram.com/faustovanin/',
  },
  {
    index: 1,
    name: 'Onilia Araujo',
    office: 'Co-Founder e CMO',
    imgProfile: OniImg,
    link_linkedin: 'https://www.linkedin.com/in/onilia-ara%C3%BAjo-a7012751/',
    link_insta: 'https://www.instagram.com/onilianeia/',
  },
]
