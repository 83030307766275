import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.areas ? "left" : "center")};
  width: 90%;
  margin: ${(props) => (props.form ? "1% 1% 1% 1%" : "8% auto auto auto")};

  @media (min-width: 1200px) {
    width: ${(props) => (props.areas ? "85%" : "70%")};
  }
`;

export const Outdoor = styled.div`
  width: 100%;
  background-color: #ffff;
  padding-top: 3%;
  padding-bottom: 5%;
  padding-right: 5%;
  padding-left: 5%;

  @media (max-width: 720px) {

    #first-title{
      padding-top: 20px;
    }

    h1{
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 720px) {
    margin-top: 7%;
    padding-top: 5%;
    padding-bottom: 0%;
    padding-right: 0%;
    padding-left: 0%;
  }
`;

export const AdjustCard = styled.div`
  margin-right: ${(props) => (props.right ? "10%" : "0%")};
  margin-left: ${(props) => (props.right ? "0%" : "10%")};

  @media (max-width: 720px) {
    margin: 0% 0% 0% 0%;
  }
`;

export const Title = styled.h1`
  color: ${(props) =>
    props.purple ? "#410B9E" : props.white ? "#FFFFFF" : "#000000"};
  font-family: "IBM Plex Sans";
  font-size: ${(props) =>
    props.semi ? "1.1vw" : props.secondtitle ? "1.8vw" : "2.5vw"};
  font-style: normal;
  margin-top: ${(props) => props.margin && "3%"};
  margin-bottom: ${(props) => props.margin && "3%"};
  text-align: ${(props) => props.margin && "center"};

  font-weight: 600;
  line-height: ${(props) =>
    props.semi ? "1.5vw" : props.secondtitle ? "2.1vw " : "3.5vw"};

  @media (max-width: 720px) {
    font-size: ${(props) =>
      props.secondtitle
        ? "4vw"
        : props.semi
        ? "3vw"
        : props.areas
        ? "6vw"
        : "5vw"};
    line-height: ${(props) =>
      props.secondtitle ? "5vw" : props.areas ? "6.5vw" : "5.5vw"};
    padding-bottom: 3%;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "l r";
  .finalButtton {
    display: none;
  }

  @media (max-width: 720px) {
    display: block;
    .container {
      display: grid;
      button {
        display: none;
      }
    }
    .finalButtton {
      display: block;
    }
  }
`;
export const ImagePrincipal = styled.img`
  height: 21vw;
  width: 100%;
  @media (max-width: 720px) {
    display: none;
  }
`;
export const ImageMobile = styled.img`
  display: none;
  @media (max-width: 720px) {
    display: flex;
    height: 40vw;
    width: 100%;
    margin-bottom: 5%;
  }
`;
export const RowMobile = styled.div`
  @media (max-width: 720px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const DefaultButton = styled.button`
  color: ${(props) => (props.textWhite ? "#FFF" : "#000")};
  font-family: "Roboto";
  font-size: ${(props) => (props.form ? "2.5vw" : "1.3vw")};
  font-style: normal;
  font-weight: 600;
  line-height: 2.5vw;
  letter-spacing: 0em;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  background-color: ${(props) =>
    props.white ? "#FFF" : props.black ? "#000000" : "#FF9400"};
  border: ${(props) => (props.white ? "1px solid #000000" : "none")};
  padding: ${(props) =>
    props.card ? "2.7% 10% 2.7% 10%" : "2.5% 2.5% 2.5% 2.5%"};
  border-radius: 0.8vw;
  margin-right:${(props) => (props.form ? "8%" : "3%")};
  margin-left:${(props) => (props.form ? "8%" : "3%")};
  margin-top: ${(props) => (props.form ? "-12%" : "0")};

  @media (max-width: 720px) {
    margin-top: ${(props) => (props.form ? "10%" : "0")};
    margin-right: 2%;
    margin-left: 0;
    display: ${(props) => (props.white ? "none" : "flex")};
    padding: ${(props) =>
      props.secondButton ? "7% 13% 7% 13%" : "5% 5% 5% 5%"};
    font-size: ${(props) => (props.form ? "6vw" : "4.5vw")};
    margin-bottom: 10%;
    border-radius: 3vw;
    justify-content: center;
  }
`;

export const Text = styled.p`
  color: ${(props) =>
    props.purple ? "#410B9E" : props.white ? "#FFF" : "#000000"};
  font-family: "IBM Plex Sans";
  font-size: ${(props) => (props.three ? "1.1vw" : "1.2vw")};
  font-style: normal;
  font-weight: ${(props) => (props.bold ? "bold" : "400")};
  line-height: 2vw;
  letter-spacing: 0em;
  text-align: ${(props) => (props.bold ? "center" : "left")};
  padding-top: ${(props) => (props.areas ? "1%" : "4.5%")};
  padding-right: ${(props) => (props.areas ? "40%" : "0")};
  margin-bottom: ${(props) => (props.areas ? "0%" : "10%")};
  margin-right: 2%;

  @media (max-width: 720px) {
    padding-top: 0%;
    padding-right: 0%;
    font-size: ${(props) => (props.three ? "2.5vw" : "3.5vw")};
    line-height: 4.2vw;
    margin-bottom: 5%;
  }
`;
export const RowButtons = styled.div`
  display: inline;
  cursor: pointer;
`;

export const DiscoverLancers = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  background: ${(props) => (props.form ? "#2D2828" : "rgba(0, 0, 0, 0.82);")};
  border-radius: ${(props) => (props.form ? "1vw" : "2vw")};
  margin-left: ${(props) => (props.form ? "0" : "5.7%")};
  margin-right: ${(props) => (props.form ? "0" : "5.7%")};
  margin-bottom: ${(props) => (props.form ? "3%" : "0")};
  padding-top: 1%;
  padding-bottom: 3%;
  height: auto;

  @media (max-width: 720px) {
    display: block;
    margin: ${(props) => (props.form ? "0 0 2% " : "1% 1% 2% 1%")};
  }
`;

export const TitleDiscover = styled.h1`
  font-family: "IBM Plex Sans";
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 4vw;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 5%;
  padding-left: 10%;
  padding-top: 3%;

  @media (max-width: 720px) {
    font-size: 6vw;
    line-height: 7vw;
    margin-top: 2%;
  }
`;

export const Experience = styled.div`
  width: 100%;
  height: auto;
  padding-top: 1%;
  padding-bottom: 5%;
  background-color: #000000;
  border-radius : 2%;

  .card {
    width: 100%;
  }

  @media (max-width: 720px) {
    border-radius: 2vw;
    padding-left: 10%;
    padding-right: 10%;
  }
`;

export const CardContent = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 720px) {
    display: block;
  }
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;

  @media (max-width: 720px) {
  }
`;

export const Option = styled.div`
  background-color: #ffffff;
  border-radius: 1vw;

  display: block;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;

  margin: 2vw;
  width: 75%;
  height: 90%;

  img {
    margin-top: 15%;
    height: 3.5vw;
  }

  @media (max-width: 720px) {
    border-radius: 2vw;
    img {
      margin-top: 5%;
      height: 10vw;
      margin-bottom: 5%;
    }
    padding: 0%;
    margin-bottom: 5%;
  }
`;

export const ThreeContent = styled.div`
  width: 100%;
  margin-bottom: 3vh;

  display: grid;
  grid-template-columns: 10fr 1fr;
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas:
    "Ideia n1"
    "Prototipo n2"
    "Mvp n3"
    "Mercado n4";
  .Ideia {
    grid-area: Ideia;
  }

  .Prototipo {
    grid-area: Prototipo;
  }

  .Mvp {
    grid-area: Mvp;
  }

  .Mercado {
    grid-area: Mercado;
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-template-areas:
      "Ideia n1"
      "n2 Prototipo"
      "Mvp n3"
      "n4 Mercado";

    .n2 {
      border-right: 2px dashed #000000;
    }
  }
`;

export const Branch = styled.div`
  height: 100%;
  align-items: ${(props) => (props.left ? "flex-start" : "flex-end")};
  justify-content: ${(props) => (props.left ? "flex-start" : "flex-end")};
`;

export const ThreeTextBox = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  margin-right: ${(props) => (props.right ? "0" : "20px")};
  border-left: ${(props) => (props.n4 ? "none" : "2px dashed #000000")};

  @media (min-width: 768px) {
    margin-left: ${(props) => (props.right ? "0px" : "0")};
    margin-right: ${(props) => (props.right ? "0" : "20px")};
    border-right: ${(props) =>
      props.right ? "0px solid #FFF" : "2px dashed #000000"};
    border-left: none;
  }
`;

export const EmpityBox = styled.div`
  width: 100%;
  position: relative;

  flex-shrink: 0;

  display: flex;
  align-items: flex-start;
  justify-content: ${(props) => (props.left ? "flex-start" : "flex-end")};
`;

export const Ball = styled.div`
  position: absolute;

  border-radius: 50%;
  display: inline-block;
  height: 20px;
  width: 20px;
  background-color: #000;

  left: -84.4vw;
  @media (min-width: 768px) {
    left: ${(props) => (props.left ? "-11px" : "98.3%")};
  }
`;

export const FormContainer = styled.div`
`;
